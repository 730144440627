<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
    <div class="card-wrap">
      <div class="card-content">
        <solution-card
          v-for="(item, idx) in cards"
          :key="idx"
          :title="item.Name"
          :desc="item.SolutionDescription"
          :imgSrc="item.ImagePath"
          :id="item.Id"
        ></solution-card>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/NormalBanner.vue";
import SolutionCard from "../components/solution/SolutionCard.vue";
import { get } from "../plugins/axios";
export default {
  components: { Banner, SolutionCard },
  data() {
    return {
      title: "解决方案",
      subtitle: "引领各行各业实现“互联网+”",
      cards: [
        // {
        //   title: "行业解决方案",
        //   desc: "施工图审查，拆迁，工程质监，订奶，地产评估等各行业的问题解决",
        //   imgSrc: "",
        //   id: "sol1",
        // },
        // {
        //   title: "IT工程解决方案",
        //   desc:
        //     "具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定",
        //   imgSrc: "",
        //   id: "sol2",
        // },
        // {
        //   title: "智慧产业园",
        //   desc:
        //     "具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定",
        //   imgSrc: "",
        //   id: "sol3",
        // },
        // {
        //   title: "行业解决方案",
        //   desc: "施工图审查，拆迁，工程质监，订奶，地产评估等各行业的问题解决",
        //   imgSrc: "",
        //   id: "sol4",
        // },
        // {
        //   title: "IT工程解决方案",
        //   desc:
        //     "具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定",
        //   imgSrc: "",
        //   id: "sol5",
        // },
        // {
        //   title: "智慧产业园",
        //   desc:
        //     "具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定，这里可以写四行，具体文案待定",
        //   imgSrc: "",
        //   id: "sol6",
        // },
      ],
    };
  },
  mounted() {
    this.$nextTick(function () {
      get("/HomeApi/QuerySolution")
        .then((data) => {
          this.cards = data;
        })
        .catch((e) => {
          console.log(e);
        });

      get(`/HomeApi/QueryItemDetailByEncode`, { encode: "JJFA" })
        .then((data) => {
          this.title = data.Name;
          this.subtitle = data.Attr3;
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style scoped>
.card-wrap {
  max-width: 1440px;
  min-width: 1260px;
  margin: 0 auto;
}
.card-content {
  margin: 30px 20px;
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(400px, 33.33%)
  ); /* minmax(33.33%, 33.33%) */
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
}

.sol-card {
  /* max-width: 450px; */
  width: 100%;
  height: 500px;
}
.img-wrap {
  width: 33%;
}
</style>
